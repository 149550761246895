import React from 'react'
import '@mantine/core/styles.css'
import './src/styles/global.scss'
import './src/fonts/fonts.css'
import { MantineProvider } from '@mantine/core'
import { signUpClick } from './src/utils/tracker'

export const onClientEntry = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const menuButton = document.querySelector('.menuButton')
    if (menuButton) {
      menuButton.addEventListener('click', () => {
        const body = document.body
        if (body.classList.contains('menu-active')) {
          body.classList.remove('menu-active')
        } else {
          body.classList.add('menu-active')
        }
      })
    }
  })
  document.addEventListener(
    'click',
    (event) => {
      const target = event.target.closest('a')
      if (!target) return

      const href = target.getAttribute('href')

      if (href === 'https://app.atlas.co/login') {
        signUpClick(event)
        return
      }

      const isExternal =
        href.startsWith('http') && !href.includes(window.location.origin)

      if (isExternal) {
        event.preventDefault()
        window.open(href, '_blank', 'noopener,noreferrer')
        return
      }
    },
    { capture: true }
  )
}

export const wrapRootElement = ({ element }) => (
  <MantineProvider withGlobalStyles withNormalizeCSS>
    {element}
  </MantineProvider>
)
